import React from "react";
import { useRef } from "react";
import "./Rates.css";

function Rates({ isActiveRates, scrollItem, onScrollItem }) {
  const ratesText = `Различные варианты тарифа майнинг-отеля позволяют выбрать оптимальный режим работы оборудования
   для майнинга в зависимости от состояния криптовалютного рынка`;

  const ratesRef = useRef();

  React.useEffect(()=>{
    if (scrollItem === "rates") {
      onScrollItem(ratesRef);
    }
  },[onScrollItem,scrollItem])

  return (
    <section ref={ratesRef} className={`rates page__container inactive ${isActiveRates ? "active" : ""}`}>
      <h2 className="rates__title">Тарифы на электроэнергию</h2>
      <ul className="rates__cards">
        <li className="rates__card">
          <div className="rates__circle"></div>
          <h3 className="rates__card-title">Тариф "Эконом"</h3>
          <p className="rates__card-uptime">up-time 60 %</p>
          <p className="rates__card-time-subtitle">Время работы майнера</p>
          <p className="rates__card-days">ПН-ПТ:</p>
          <p className="rates__card-time">выключение в 07:00 МСК</p>
          <p className="rates__card-time">включение в 22:00 МСК</p>
          <p className="rates__card-days">СБ-ВС и праздничные дни:</p>
          <p className="rates__card-time">круглосуточно</p>
          <p className="rates__card-price">3,00 руб./кВт*ч в т.ч. НДС</p>
        </li>
        <li className="rates__card">
          <div className="rates__circle"></div>
          <h3 className="rates__card-title">Тариф "Эконом+"</h3>
          <p className="rates__card-uptime">up-time 91,5 %</p>
          <p className="rates__card-time-subtitle">Время работы майнера</p>
          <p className="rates__card-days">ПН-ПТ:</p>
          <p className="rates__card-time">выключение в 09:00 МСК</p>
          <p className="rates__card-time">включение в 13:00 МСК</p>
          <p className="rates__card-days">СБ-ВС и праздничные дни:</p>
          <p className="rates__card-time">круглосуточно</p>
          <p className="rates__card-price">4,00 руб./кВт*ч в т.ч. НДС</p>
        </li>
        <li className="rates__card">
          <div className="rates__circle"></div>
          <h3 className="rates__card-title">Тариф "Стандарт"</h3>
          <p className="rates__card-uptime">up-time 99 %</p>
          <p className="rates__card-time-subtitle">Время работы майнера</p>
          <p className="rates__card-time rates__card-time_place_standard">
            круглосуточно
          </p>
          <p className="rates__card-price rates__card-price_place_standard">
            5,00 руб./кВт*ч в т.ч. НДС
          </p>
        </li>
      </ul>
      <p className="rates__caption">{ratesText}</p>
    </section>
  );
}

export default Rates;
