import React from "react";
import { useRef } from "react";
import advantage1Image from "../../../images/image1.jpg";
import advantage2Image from "../../../images/image2.jpg";
import advantage3Image from "../../../images/image3.jpg";
import advantage4Image from "../../../images/image4.jpg";
import advantage5Image from "../../../images/image5.jpg";
import advantage6Image from "../../../images/image6.jpg";
import advantage7Image from "../../../images/image7.jpg";
import advantage8Image from "../../../images/image8.jpg";
import "./Advantages.css";

function Advantages({ isActiveAdvantages, scrollItem, onScrollItem }) {
  const companyName = "B-POWER mining ";

  const attentionText1 = `сдан в эксплуатацию в январе 2023 года.
   Технологическое присоединение от подстанции 220 кВ по построенными новыми ЛЭП 10 кВ.
   На территории майнинг-отеля установлено новое энергооборудование, от которого осуществляется подключение контейнеров,
   что обеспечивает бесперебойную и надежную систему энергоснабжения`;

  const attentionText2FirstHalf = `Покупка электроэнергии для клиентов происходит собственной энергосбытовой компанией `;
  const attentionText2SecondHalf = `на оптовом рынке электроэнергии без посредников`;

  const advantagesRef = useRef();


  React.useEffect(()=>{
    if (scrollItem === "advantages") {
      onScrollItem(advantagesRef);
    }
  },[onScrollItem,scrollItem])

  return (
    <section ref={advantagesRef} className="advantages page__container">
      <div className="advantages__container">
        <h2 className="advantages__subtitle">B-POWER</h2>
        <h4 className="advantages__subtitle-postscript">майнинг-отель</h4>
      </div>

      <ul className="advantages__cards">
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage1Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">
            Официальный тариф на электроэнергию с предоставлением счета-фактуры
            с НДС
          </p>
        </li>
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage2Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">
            Собственная энергосбытовая компания
          </p>
        </li>
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage3Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">
            Заключение договора энергоснабжения
          </p>
        </li>
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage4Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">
            Контроль электроэнергии через автоматизированную систему
          </p>
        </li>
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage5Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">
            Два канала связи сети Интернет
          </p>
        </li>
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage6Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">
            Закрытая охраняемая территория без соседей
          </p>
        </li>
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage7Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">
            Круглосуточное видеонаблюдение 32 камеры
          </p>
        </li>
        <li className="advantages__card">
          <img
            className="advantages__image"
            src={advantage8Image}
            alt="Изображение преимущества"
          ></img>
          <p className="advantages__description">Сервисный центр на месте</p>
        </li>
      </ul>

      <article className={`advantages__attention-container inactive ${isActiveAdvantages ? "active" : ""}`}>
        <h3 className="advantages__attention-title">
          Обращаем внимание клиента
        </h3>
        <div className="advantages__attention-text-container">
          <span className="advantages__attention-text advantages__attention-text_size_l">
            {companyName}
          </span>
          <p className="advantages__attention-text">{attentionText1}</p>
        </div>
        <div className="advantages__attention-text-container">
          <p className="advantages__attention-text">
            {attentionText2FirstHalf}
          </p>
          <span className="advantages__attention-text advantages__attention-text_size_l">
            {companyName}
          </span>
          <p className="advantages__attention-text">
            {attentionText2SecondHalf}
          </p>
        </div>
      </article>
    </section>
  );
}

export default Advantages;
