import React from "react";
import "./Preloader.css";

function Preloader({ isLoading }) {
  return (
    <div className={`${isLoading ? "preloader_fullwidth" : "preloader"}`}>
      <span className="cssload__whirlpool"></span>
    </div>
  );
}

export default Preloader;
