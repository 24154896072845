import React from "react";
import "./App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import Preloader from "../Preloader/Preloader";

function App() {
  let [scrollItem, setScrollItem] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isColumnMenu, setIsColumnMenu] = React.useState(null);
  const [isScrollCompensation, setIsScrollCompensation] = React.useState(null);
  const [isScrollDown, setIsScrollDown] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
  }, []);

  React.useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [isLoading]);

  React.useEffect(() => {
    const bodyObject = document.body;
    const bodyObjectStyle = document.body.style;
    if (isColumnMenu && isScrollCompensation === null) {
      const bodyWidthWithScroll = bodyObject.getBoundingClientRect().width;
      bodyObjectStyle.overflow = "hidden";
      const bodyWidthWithoutScroll =
      bodyObject.getBoundingClientRect().width;
      const compensation = bodyWidthWithoutScroll - bodyWidthWithScroll;
      bodyObjectStyle.paddingRight = `${compensation}px`;
      setIsScrollCompensation(compensation);
    } else if (!isColumnMenu && isScrollCompensation !== null) {
      bodyObjectStyle.paddingRight = "unset";
      bodyObjectStyle.overflow = "unset";
      setIsScrollCompensation(null);
    }
  }, [isColumnMenu, isScrollCompensation]);

  function handleColumnMenu(state) {
    setIsColumnMenu(state);
  }

  function handleClickNav(str) {
    setScrollItem(str);
  }

  function handleScrollItem(itemChosen) {
    const yOffSet = itemChosen.current.offsetTop - 90;
    window.scrollTo({ top: yOffSet, behavior: "smooth" });
    setScrollItem(null);
  }

  function handleScrollDown(state) {
    setIsScrollDown(state);
  }

  return (
    <div
      className={`page fontoptimisation ${isLoading ? "page_no-scroll" : ""}`}
    >
      {isLoading && <Preloader isLoading={isLoading} />}
      <Header
        isLoading={isLoading}
        onClickNav={handleClickNav}
        isColumnMenu={isColumnMenu}
        handleColumnMenu={handleColumnMenu}
        isScrollCompensation={isScrollCompensation}
        isScrollDown={isScrollDown}
      />
      <Main
        isLoading={isLoading}
        scrollItem={scrollItem}
        onScrollItem={handleScrollItem}
        onScrollDown={handleScrollDown}
      />
      <Footer
        isLoading={isLoading}
        scrollItem={scrollItem}
        onScrollItem={handleScrollItem}
      />
    </div>
  );
}

export default App;
