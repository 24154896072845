import React from "react";
import "./Header.css";
import Logo from "../Logo/Logo";
import Navigation from "./Navigation/Navigation";

function Header({
  isLoading,
  onClickNav,
  isColumnMenu,
  handleColumnMenu,
  isScrollCompensation,
  isScrollDown
}) {
  return (
    <>
      <header
        className={`header page__container ${
          (isLoading && !isScrollDown) || isScrollDown  ? "inactive disappear" : "appear"
        }`}
      >
        <Logo />
        <Navigation
          onClickNav={onClickNav}
          isColumnMenu={isColumnMenu}
          handleColumnMenu={handleColumnMenu}
          isScrollCompensation={isScrollCompensation}
        />
      </header>
    </>
  );
}

export default Header;
