import "./About.css";
import aboutImage from "../../../images/ellipseMining.png";

function About() {

  return (
    <section className="about">
      <div className="about__container">
        <h1 className="about__title">Дата-центр</h1>
        <p className="about__text">
          Размещение оборудования для майнинга<br/>на севере Костромской области<br/>в 400 км от Москвы
        </p>
        <h2 className="about__subtitle ">Мощность дата-центра 20 МВт</h2>
        <p className="about__text">
          Размещение от 300 кВт
        </p>
      </div>
      <img className="about__image" src={aboutImage} alt="Изображение майнингового оборудования"></img>
    </section>
  )
}

export default About;