import React from "react";
import "./Main.css";
import About from "./About/About";
import Advantages from "./Advantages/Advantages";
import Rates from "./Rates/Rates";
import Service from "./Service/Service";

function Main({ isLoading, scrollItem, onScrollItem, onScrollDown }) {
  const [isActiveAdvantages, setIsActiveAdvantages] = React.useState(false);
  const [isActiveRates, setIsActiveRates] = React.useState(false);
  const [isActiveService, setIsActiveService] = React.useState(false);
  let prevYPos = React.useRef(0);


  React.useEffect(() => {
    function checkScroll() {

      window.pageYOffset > 950
        ? setIsActiveAdvantages(true)
        : setIsActiveAdvantages(false);
      window.pageYOffset > 1350
        ? setIsActiveRates(true)
        : setIsActiveRates(false);
      window.pageYOffset > 2050
        ? setIsActiveService(true)
        : setIsActiveService(false);
    }

    function checkScrollDown() {
      if (window.pageYOffset >= prevYPos.current && window.pageYOffset > 20) {
        onScrollDown(true);
      } else {
        onScrollDown(false);
      }
      prevYPos.current = window.pageYOffset;
    }

    window.addEventListener("scroll", checkScroll);
    window.addEventListener("scroll", checkScrollDown);
    window.addEventListener("touchend", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
      window.removeEventListener("scroll", checkScrollDown);
      window.removeEventListener("touchend", checkScroll);
    };
  }, [onScrollDown, scrollItem]);
  return (
    <main
      className={`main page__container ${
        isLoading ? "inactive" : "active appear"
      }`}
    >
      <About />
      <Advantages
        isActiveAdvantages={isActiveAdvantages}
        scrollItem={scrollItem}
        onScrollItem={onScrollItem}
      />
      <Rates
        isActiveRates={isActiveRates}
        scrollItem={scrollItem}
        onScrollItem={onScrollItem}
      />
      <Service
        isActiveService={isActiveService}
        scrollItem={scrollItem}
        onScrollItem={onScrollItem}
      />
    </main>
  );
}

export default Main;
