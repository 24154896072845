import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./components/App/App";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.querySelector(".root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();