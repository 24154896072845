import "./Logo.css";
import headerLogo from "../../images/logo.svg";

function Logo() {

  return (
    <div className="logo appear fontoptimisation">
      <img
        className="logo__image"
        src={headerLogo}
        alt="Логотип B-Power"
      />
      <p className="logo__title">B-POWER</p>
      <p className="logo__subtitle">mining</p>
    </div>
  );
}

export default Logo;
