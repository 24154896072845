import React from "react";
import { useRef } from "react";
import "./Service.css";
import serviceImage from "../../../images/ellipseService.png";

function Service({ isActiveService, scrollItem, onScrollItem }) {
  const serviceRef = useRef();

  React.useEffect(()=>{
    if (scrollItem === "service") {
      onScrollItem(serviceRef);
    }
  },[onScrollItem,scrollItem])

  return (
    <section ref={serviceRef} className={`service page__container inactive ${isActiveService ? "active" : ""}`}>
      <img
        className="service__image"
        src={serviceImage}
        alt="Изображение "
      ></img>
      <div className="service__container">
        <h2 className="service__title">Сервисный центр</h2>
        <ul className="service__text-container">
          <li className="service__text">
            На территории дата-центра без передачи третьим лицам
          </li>
          <li className="service__text">
            Ремонт хэш-плат и блоков питания всех моделей Antminer, Innosilicon,
            Whatsminer
          </li>
          <li className="service__text">
            Работа сертифицированных специалистов, прошедших профессиональную
            подготовку
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Service;
