import React from "react";
import "./Navigation.css";

function Navigation({
  onClickNav,
  isColumnMenu,
  handleColumnMenu,
  isScrollCompensation,
}) {
  React.useEffect(() => {
    function handleOverlayClick(evt) {
      if (evt.target.classList.contains("overlay_active")) {
        handleColumnMenu(false);
      }
    }
    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, [isColumnMenu, handleColumnMenu]);

  function handleClickNav(str) {
    if (isColumnMenu) handleColumnMenu(false);
    onClickNav(str);
  }

  function handleClickMenuOrCloseButton() {
    !isColumnMenu ? handleColumnMenu(true) : handleColumnMenu(false);
  }

  return (
    <div
      className={`navigation ${isColumnMenu ? "overlay overlay_active" : ""}`}
    >
      <nav
        className={`navigation__container ${
          isColumnMenu ? "navigation__container_type_column" : ""
        }`}
      >
        <ul
          className={`navigation__items ${
            isColumnMenu
              ? "navigation__items_type_column"
              : "navigation__items_inactive"
          }`}
        >
          <li
            onClick={() => handleClickNav("advantages")}
            className="navigation__item"
          >
            Наши преимущества
          </li>
          <li
            onClick={() => handleClickNav("rates")}
            className="navigation__item"
          >
            Тарифы
          </li>
          <li
            onClick={() => handleClickNav("service")}
            className="navigation__item"
          >
            Сервис
          </li>
          <li
            onClick={() => handleClickNav("footer")}
            className="navigation__item"
          >
            Контакты
          </li>
        </ul>
        <button
          onClick={handleClickMenuOrCloseButton}
          type="button"
          aria-label="Кнопка открытия или закрытия меню"
          className={`navigation__btn-menu ${
            isColumnMenu ? "navigation__btn-menu_inactive appear" : ""
          }`}
          style={
            isColumnMenu
              ? { marginRight: `${isScrollCompensation}px` }
              : { marginRight: "unset" }
          }
        >
          <div className="navigation__burger-line"></div>
          <div className="navigation__burger-line"></div>
          <div className="navigation__burger-line"></div>
        </button>
      </nav>
    </div>
  );
}

export default Navigation;
