import React from "react";
import { useRef } from "react";
import "./Footer.css";
import Logo from "../Logo/Logo";
import phoneImage from "../../images/phone-image.svg";
import addressImage from "../../images/address-image.svg";
import mailImage from "../../images/mail-image.svg";

function Footer({ isLoading, scrollItem, onScrollItem }) {
  const footerRef = useRef();

  React.useEffect(()=>{
    if (scrollItem === "footer") {
      onScrollItem(footerRef);
    }
  },[onScrollItem,scrollItem])


  return (
    <>
      <footer ref={footerRef} className={`footer page__container ${isLoading ? "inactive" : "active appear"}`}>
        <div className="footer__logo-container">
          <Logo />
          <a
            className="footer__logo-link"
            href="https://ru.freepik.com/free-vector/letter-b-logo-corporate-business-technology_14241047.htm#query=logo%20b&position=0&from_view=keyword#position=0&query=logo%20b"
            target="_blank"
            rel="noopener noreferrer"
          >
            Изображение от sentavio на Freepik
          </a>
        </div>
        <div className="footer__contacts-container">
          <h2 className="footer__contacts-title">Контакты</h2>
          <nav>
            <ul className="footer__contacts-items">
              <li className="footer__address">
                <img
                  className="footer__contacts-icon"
                  src={addressImage}
                  alt="Изображение отметки на карте"
                />
                <a
                  className="footer__contacts-link"
                  href="https://yandex.ru/maps/?pt=43.947749,56.227250&z=18&l=map"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  603137, г. Нижний Новгород, ул. 40 лет Победы, д. 4, пом. П7
                </a>
              </li>
              <li className="footer__item">
                <img
                  className="footer__contacts-icon"
                  src={phoneImage}
                  alt="Изображение трубки телефона"
                />
                <a className="footer__contacts-link" href="tel:+79876676121">
                  +7 (987) 667-61-21
                </a>
              </li>
              <li className="footer__item">
                <img
                  className="footer__contacts-icon"
                  src={phoneImage}
                  alt="Изображение трубки телефона"
                />
                <a className="footer__contacts-link" href="tel:+79101411597">
                  +7 (910) 141-15-97
                </a>
              </li>
              <li className="footer__item">
                <img
                  className="footer__contacts-icon"
                  src={mailImage}
                  alt="Изображение почты"
                />
                <a
                  className="footer__contacts-link"
                  href="mailto:b-powermining@yandex.ru"
                >
                  b-powermining@yandex.ru
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </>
  );
}

export default Footer;
